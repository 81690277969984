import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kim/Documents/MedGrocer/pp-jn-website/src/components/Layout/Layout.js";
import { graphql, navigate, Link } from "gatsby";
import classNames from 'classnames';
import Img from "gatsby-image";
import HeroBanner from "../components/Elements/HeroBanner";
import MediaBox from "../components/Elements/MediaBox";
import Hero from "../components/Layout/Hero";
import Description from "../components/StaticPages/Careers/Description";
import Container from "../components/Layout/Container";
import Button from "../components/Elements/Button";
import SEO from "components/Layout/SEO";
import career__flexible from "../../static/images/career/career__flexible.png";
import career__healthcare from "../../static/images/career/career__healthcare.png";
import career__startup from "../../static/images/career/career__startup.png";
import career__ops from "../../static/images/career/career__ops.png";
import career__medical from "../../static/images/career/career__medical.png";
import career__support from "../../static/images/career/career__support.png";
import styles from "../../src/components/StaticPages/utils/staticPages.module.scss";
export const query = graphql`
  query {
    career: file(relativePath: { eq: "career/career__brain--orange-square.png" })
    {
      childImageSharp {
        fluid(
          maxWidth: 2000
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
   }`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">







    <SEO title="Careers" mdxType="SEO" />
    <HeroBanner image={{
      "src": props.data.career.childImageSharp.fluid.src,
      "alt": "MedGrocer"
    }} color="warning" className={classNames("py-3", styles["careers"])} mdxType="HeroBanner">
      <h1>Grow with your goals</h1>
      <p className="mt-2 subtitle is-size-7-tablet is-size-7-mobile">
        <p>{`We provide companies and their employees with medicine services and complementary healthcare solutions. Our technology platform saves time and money while enabling better service and smarter choices.`}</p>
      </p>
      <p className="mt-1 subtitle is-size-7-tablet is-size-7-mobile mb-2">
        <p>{`MedGrocer is always hiring. Send us your CV and your portfolio below.`}</p>
      </p>
      <a href="mailto:hr@medgrocer.com" target="_blank" rel="noopener noreferrer"><Button color='secondary' className={styles["careers__button"]} mdxType="Button">Apply</Button></a>
    </HeroBanner>
    <Hero className="mt-5-desktop mt-5-fullhd" mdxType="Hero">
      <Container isCentered desktop={10} fullhd={10} className="mt-5-desktop mt-5-fullhd" mdxType="Container">
        <div className="content has-text-centered pb-2" />
        <div className="columns is-centered content has-text-centered">
          <MediaBox title="Operations" image={{
            src: career__ops,
            alt: "ops"
          }} mdxType="MediaBox">
            <ul>
              <li parentName="ul">
                <p parentName="li">{`Product Development`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`Business Development`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`Client Supply Chain`}</p>
              </li>
            </ul>
          </MediaBox>
          <MediaBox title="Support" image={{
            src: career__support,
            alt: "support"
          }} mdxType="MediaBox">
            <ul>
              <li parentName="ul">
                <p parentName="li">{`Finance`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`Human Resources`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`Developers`}</p>
              </li>
            </ul>
          </MediaBox>
          <MediaBox title="Medical" image={{
            src: career__medical,
            alt: "medical"
          }} mdxType="MediaBox">
            <ul>
              <li parentName="ul">
                <p parentName="li">{`Pharmacists`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`Nurses and Doctors`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`Psychologists and Psychiatrists`}</p>
              </li>
            </ul>
          </MediaBox>
        </div>
      </Container>
    </Hero>
    <Hero mdxType="Hero">
      <div className="content has-text-centered pb-2">
        <h1>{`Why work with us`}</h1>
      </div>
      <Container isCentered desktop={10} fullhd={10} mdxType="Container">
        <div className='columns is-centered content has-text-centered'>
          <MediaBox title="Future of Healthcare" image={{
            src: career__healthcare,
            alt: "healthcare"
          }} mdxType="MediaBox">
            <p>{`We are redefining the Philippine healthcare system through medicine insurance, mental health in the workplace, and patient access.`}</p>
          </MediaBox>
          <MediaBox title="Startup Environment" image={{
            src: career__startup,
            alt: "healthcare"
          }} mdxType="MediaBox">
            <p>{`We combine startup dynamism with corporate structure to balance business and social impact with personalized development.`}</p>
          </MediaBox>
          <MediaBox title="Flexible Roles" image={{
            src: career__flexible,
            alt: "healthcare"
          }} mdxType="MediaBox">
            <p>{`We don't have conventional job descriptions, and our members explore multiple disciplines they are passionate about.`}</p>
          </MediaBox>
        </div>
      </Container>
    </Hero>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      